<template>
	<v-sheet class="claims-main-listing" style="height: calc(100vh - 88px)">
		<v-layout class="flex-wrap d-flex align-center">
			<v-flex class="py-0" md3>
				<v-select
					v-model="status"
					class="listing-select"
					hide-details
					:disabled="pageLoading"
					:items="listingStatus"
					item-text="text"
					item-value="value"
					label="All Claims"
					solo
					style="max-width: 185px"
					@change="statusFilterRows($event)"
					:menu-props="{ offsetY: true, contentClass: 'rounded-lg', minWidth: '175px' }"
				>
					<template #item="{ item }">
						<div class="my-1 d-flex align-center">
							<div style="width: 57px">
								<v-chip size="26" class="px-2" dark :color="item.status_color">
									{{ item.status_count }}
								</v-chip>
							</div>
							<div>
								<div class="ml-2 fw-500 text-uppercase" v-text="item.text"></div>
							</div>
						</div>
					</template>
				</v-select>
			</v-flex>

			<v-spacer></v-spacer>
			<v-flex class="text-end">
				<v-btn
					v-if="this.getPermission('claim:create')"
					depressed
					tile
					color="red darken-4"
					class="text-white mr-2"
					:to="{
						name: 'create-claims',
						query: { ...this.$route?.query, t: new Date().getTime() },
					}"
				>
					<v-icon left> mdi-plus </v-icon>
					Create
				</v-btn>
				<v-btn
					v-if="false"
					depressed
					color="blue darken-4"
					class="text-white mr-2"
					tile
					@click="viewType = !viewType"
				>
					<v-icon v-if="viewType">mdi-view-list</v-icon>
					<v-icon v-else>mdi-grid</v-icon>
				</v-btn>
				<!-- <v-btn
					depressed
					:disabled="pageLoading"
					color="blue darken-4"
					class="text-white mr-2"
					
					tile
				> -->
				<v-icon color="blue darken-4" class="py-0 mr-2" tile size="34" @click="routeSetting"
					>mdi-cog-box</v-icon
				>
				<!-- </v-btn> -->

				<v-menu
					bottom
					left
					origin="center center"
					transition="slide-y-transition"
					rounded="0"
					offset-y
					max-height="400px"
					:close-on-content-click="false"
					content-class="white-background"
					v-model="actionMenu"
				>
					<template v-slot:activator="{ on, attrs }">
						<v-btn
							depressed
							color="blue darken-4"
							class="text-white"
							:disabled="pageLoading"
							v-bind="attrs"
							v-on="on"
							tile
						>
							<v-icon> mdi-table-edit </v-icon>
						</v-btn>
					</template>
					<Draggable
						tag="ul"
						v-model="draggableThead"
						class="draggable-group"
						handle=".draggable-drag-icon"
						v-on:change="updateTable('member')"
						v-if="actionMenu"
					>
						<template v-for="cols in draggableThead">
							<li class="draggable-group-item" :key="cols.key" v-if="!cols.checkbox">
								<v-checkbox
									dense
									v-model="draggableTheadShow"
									v-bind:value="cols.key"
									:label="cols.name"
									:disabled="cols.fixed || pageLoading"
									color="blue"
									hide-details
									class="mt-0 mb-0"
									v-on:change="updateTableVisiblity('claim')"
								></v-checkbox>
								<v-icon
									v-if="!pageLoading && !cols.fixed"
									class="draggable-action draggable-drag-icon"
									right
									color="blue"
									>mdi-drag</v-icon
								>
								<v-icon v-else right class="draggable-action" color="grey">mdi-drag</v-icon>
							</li>
						</template>
					</Draggable>
				</v-menu>
			</v-flex>
		</v-layout>

		<v-layout>
			<v-flex class="py-0 my-auto d-flex justify-content-end text-right listing-right">
				<template v-if="selected.length > 0">
					<v-menu
						bottom
						left
						origin="center center"
						transition="slide-y-transition"
						rounded="0"
						offset-y
						max-height="400px"
						:close-on-content-click="false"
						content-class="white-background"
						v-if="listingBulkAction.length"
					>
						<template #activator="{ on, attrs }">
							<v-btn
								depressed
								color="blue darken-4"
								class="text-white"
								tile
								:disabled="pageLoading"
								v-bind="attrs"
								v-on="on"
							>
								<v-icon left>mdi-chevron-down</v-icon>
								Bulk Action
							</v-btn>
						</template>
						<v-list class="py-0">
							<template v-for="(item, i) in listingBulkAction">
								<v-list-item link :key="i" v-on:click="doAction(item.value)">
									<v-list-item-title
										><v-icon v-if="item.icon" left :color="item.color">{{ item.icon }}</v-icon
										>{{ item.title }}</v-list-item-title
									>
								</v-list-item>
								<v-divider v-if="listingBulkAction[i + 1]" :key="`d` + i" class="m-0 p-0"></v-divider>
							</template>
						</v-list>
					</v-menu>
					<v-btn
						depressed
						:disabled="pageLoading"
						tile
						v-on:click="clearSelection"
						color="red lighten-1"
						class="text-white"
					>
						<v-icon left> mdi-close </v-icon>
						Clear Selections
					</v-btn>
				</template>
				<template v-else>
					<v-flex class="d-flex">
						<!-- <div class="mr-2 fw-600 my-auto">Filter By</div> -->
						<!-- <div class="filterTagSelect">
							<v-autocomplete
								v-model="filter_tag"
								:items="tagItems"
								:menu-props="{ bottom: true, offsetY: true }"
								prepend-inner-icon="mdi-tag"
								outlined
								hide-details
								class="filterSelect"
								content-class="filterSelectContent"
							>
							</v-autocomplete>
						</div> -->
						<!-- <div class="filterTagSelect ml-3">
							<v-autocomplete
								v-model="filter_label"
								:items="labelItems"
								:menu-props="{ bottom: true, offsetY: true }"
								prepend-inner-icon="mdi-circle"
								:color="filter_label == 'Hot' ? 'red' : filter_label == 'Warm' ? 'orange' : 'blue'"
								:class="
									filter_label == 'Hot' ? 'red-icon' : filter_label == 'Warm' ? 'orange-icon' : 'blue-icon'
								"
								outlined
								hide-details
								class="filterSelect"
								content-class="filterSelectContent"
							>
								<template v-slot:item="data">
									<template>
										<v-list-item-icon class="mr-0 my-1">
											<v-icon
												class="mdi-18px"
												:color="data.item == 'Hot' ? 'red' : data.item == 'Warm' ? 'orange' : 'blue'"
												>mdi-circle</v-icon
											>
										</v-list-item-icon>
										<v-list-item-content>
											<v-list-item-title v-html="data.item"></v-list-item-title>
										</v-list-item-content>
									</template>
								</template>
							</v-autocomplete>
						</div> -->

						<div class="ml-3 mr-2 fw-600 my-auto">Filter By</div>
						<div v-if="allowUserFilter()" class="ml-3">
							<!-- v-on:click:clear="(defaultFilter = {}), getListing()" -->
							<v-autocomplete
								:items="userItems"
								:menu-props="{ bottom: true, offsetY: true }"
								outlined
								hide-details
								item-text="display_name"
								clearable
								item-value="id"
								v-model="user_filter"
								placeholder="Select User Name"
								style="min-width: 280px !important; max-width: 300px !important"
								@change="commanFilter($event, 'users')"
							>
								<template #selection="data">
									<v-chip small v-bind="data.attrs" :input-value="data.selected">
										<v-avatar left>
											<img v-if="data.item.profile_img" :src="data.item.profile_img" />
											<img v-else src="https://ams-dev.businessthrust.com//media/misc/no_photo_found.png" />
										</v-avatar>

										<div class="fw-600 text-uppercase">{{ data.item.display_name }}</div>
									</v-chip>
								</template>
								<template v-slot:item="data">
									<template>
										<v-list-item-avatar>
											<img v-if="data.item.profile_img" :src="data.item.profile_img" />
											<img v-else src="https://ams-dev.businessthrust.com//media/misc/no_photo_found.png" />
										</v-list-item-avatar>
										<v-list-item-content>
											<v-list-item-title v-html="data.item.display_name"></v-list-item-title>
										</v-list-item-content>
									</template>
								</template>
							</v-autocomplete>
						</div>

						<div class="ml-3">
							<!-- v-on:click:clear="(defaultFilter = {}), getListing()" -->
							<v-autocomplete
								:items="claimTypes"
								:menu-props="{ bottom: true, offsetY: true }"
								outlined
								clearable
								hide-details
								v-model="customStatus"
								item-text="text"
								item-value="value"
								placeholder="Select Claim Type"
								v-on:change="commanFilter($event, 'claim')"
							></v-autocomplete>
							<!-- v-on:change="commanFilter($event, 'category')"-->
						</div>
						<div class="ml-3">
							<!-- v-on:click:clear="(defaultFilter = {}), getListing()" -->
							<v-autocomplete
								:items="categories"
								:menu-props="{ bottom: true, offsetY: true }"
								outlined
								clearable
								hide-details
								v-model="category_type"
								item-text="text"
								item-value="value"
								placeholder="Select Category"
								v-on:change="commanFilter($event, 'category')"
							></v-autocomplete>
						</div>
						<div class="ml-3">
							<!-- 08/02/2024 - 15/02/2024 -->
							<DateRangePicker
								hide-details
								hide-top-margin
								clearable
								@click:clear="
									($event) => {
										filter_date = [];
										commanFilter($event, 'date');
									}
								"
								placeholder="Date Range"
								v-model="filter_date"
								@change="
									($event) => {
										if (filter_date.length != 1) {
											commanFilter($event, 'date');
										}
									}
								"
							></DateRangePicker>
							<!-- Array.isArray($event) && $event.length > 1 && -->
						</div>
					</v-flex>
					<!-- <v-btn
						depressed
						:disabled="pageLoading"
						color="blue darken-4"
						class="text-white"
						tile
						v-on:click="drawerFilter = true"
					>
						<v-icon>mdi-filter</v-icon>
					</v-btn> -->
					<!-- <v-menu
						bottom
						left
						origin="center center"
						transition="slide-y-transition"
						rounded="0"
						offset-y
						max-height="400px"
						:close-on-content-click="false"
						content-class="white-background"
					>
						<template v-slot:activator="{ on, attrs }">
							<v-btn
								depressed
								color="blue darken-4"
								class="text-white"
								tile
								:disabled="pageLoading"
								v-bind="attrs"
								v-on="on"
							>
								<v-icon>mdi-menu</v-icon>
							</v-btn>
						</template>
						<v-list class="py-0">
							<template v-for="(item, i) in listingAction">
								<v-list-item link :key="i" v-on:click="doAction(item.value)">
									<v-list-item-title
										><v-icon v-if="item.icon" left :color="item.color">{{ item.icon }}</v-icon
										>{{ item.title }}</v-list-item-title
									>
								</v-list-item>
								<v-divider v-if="listingAction[i + 1]" :key="`d` + i" class="m-0 p-0"></v-divider>
							</template>
						</v-list>
					</v-menu> -->

					<!-- <v-btn
						depressed
						color="blue darken-4"
						class="text-white"
						tile
						v-on:click="summary_show = !summary_show"
						:disabled="pageLoading"
					>
						<v-icon>mdi-chart-box-outline</v-icon>
					</v-btn> -->
				</template>
			</v-flex>
		</v-layout>
		<v-layout
			class="page-summary"
			:class="summary_show ? 'show mt-2' : ''"
			style="display: grid; grid-template-columns: repeat(4, 1fr)"
		>
			<template v-for="(statusData, key) in listingStatus">
				<PageSummeryBox
					:key="key"
					:color="statusData.status_color"
					:text="statusData.text"
					:count="statusData.status_count"
					@click="status != statusData.value && statusFilterRows(statusData.value)"
				/>
			</template>

			<v-flex
				v-if="false"
				class="summary-details bg-light-orange"
				v-on:click="commanFilter('customer', 'category')"
				style="padding: 8px 5px 0px 0px"
			>
				<div class="name orange--text darker text-capitalize ml-2" style="text-align: left !important">
					Customer Claims
				</div>
				<div class="d-flex align-itms-center">
					<div>
						<v-icon style="margin-left: -10px" color="cyan">mdi-file-chart-outline</v-icon>
					</div>
				</div>
				<div class="number orange--text darker ms-2 mt-2">{{ customerClaim }}</div>
			</v-flex>
			<v-flex
				v-if="false"
				class="summary-details bg-light-red"
				v-on:click="commanFilter('lead', 'category')"
				style="padding: 8px 5px 0px 0px"
			>
				<div class="name red--text darker text-capitalize ml-2" style="text-align: left !important">
					Lead Claims
				</div>
				<div class="d-flex align-itms-center">
					<div>
						<v-icon style="margin-left: -10px" color="cyan">mdi-file-chart-outline</v-icon>
					</div>
				</div>
				<div class="number red--text darker ms-2 mt-2">{{ leadClaim ? leadClaim : 0 }}</div>
			</v-flex>
			<v-flex
				v-if="false"
				class="summary-details bg-light-green"
				v-on:click="commanFilter('other', 'category')"
				style="padding: 8px 5px 0px 0px"
			>
				<div class="name green--text darker text-capitalize ml-2" style="text-align: left !important">
					Other Claims
				</div>
				<div class="d-flex align-itms-center">
					<div>
						<v-icon style="margin-left: -10px" color="cyan">mdi-file-chart-outline</v-icon>
					</div>
				</div>
				<div class="number green--text darker ms-2 mt-2">{{ owanClaim }}</div>
			</v-flex>
		</v-layout>
		<SearchCriteria
			v-if="search_Enabled()"
			:search-fields="listingFilter"
			search-string="Claim #, Reference, Customer, Amount, Category, Expense Date and Added At"
			v-on:advance-search="advanceSearch = true"
			v-on:close-search="resetSearch"
		></SearchCriteria>
		<template v-if="addvanceFilterAarry.length > 0">
			<v-layout class="btx-search-criteria">
				<v-flex md10>
					<h6>Search Criteria</h6>

					<ul>
						<li>
							Advance Filter
							<span class="search-text"></span>
						</li>
					</ul>
				</v-flex>
				<v-flex md2 class="text-right">
					<v-btn
						depressed
						color="red lighten-1"
						class="text-white"
						tile
						text
						small
						v-on:click="statusFilterRows('all')"
					>
						<v-icon>mdi-close</v-icon>
					</v-btn>
				</v-flex>
			</v-layout>
		</template>
		<template v-if="viewType">
			<!-- <ClaimsKanban></ClaimsKanban> -->
			<ClaimsKanbanNew
				@loaded="pageLoading = false"
				:custom-status="customStatus"
				@scucess="getClaimStatus()"
			/>

			<!--  v-on:update:lead="update_lead($event)"-->
		</template>
		<template v-else>
			<Table
				:page-loading="pageLoading"
				type="claim"
				delete-endpoint="claim/"
				detail-route="claims-details"
				update-route="update-claim"
				v-on:reload:content="filterRows"
				:status-filter-data="statusFilterData"
			></Table>
		</template>
		<ExportDialog
			endpoint="member/export?type=member&current=1"
			title="Members"
			current
			:export-dialog.sync="exportCurrentDialog"
			v-on:close="exportCurrentDialog = false"
		></ExportDialog>
		<ExportDialog
			endpoint="member/export?type=member"
			title="Members"
			:export-dialog.sync="exportDialog"
			v-on:close="exportDialog = false"
		></ExportDialog>
		<ImportDialog
			endpoint="member/import?type=member"
			title="Members"
			:import-dialog.sync="importDialog"
			v-on:close="importDialog = false"
		></ImportDialog>
		<FilterDialog
			title="Members"
			:filter-dialog.sync="advanceSearch"
			:btx-filter.sync="listingFilter"
			:btx-query.sync="btxQuery"
			v-on:do-search="doAdvanceSearch"
			v-on:close="advanceSearch = false"
		>
		</FilterDialog>
		<AdvanceFilter
			v-on:addvanceFilterData="dataFilter"
			:advanceSearch="drawerFilter"
			v-on:close="drawerFilter = false"
			search-endpoint="claim"
			search-title="Claim"
		></AdvanceFilter>
	</v-sheet>
</template>

<script>
// import ClaimsKanban from "@/view/module/claims/ClaimsKanban";
// import moment from "moment-timezone";
// import { SET_ERROR } from "@/core/services/store/common.module";
import ListingMixin from "@/core/mixins/listing.mixin";
import {
	SET_TBODY,
	SET_SHOWING_STRING,
	SET_THEAD,
	SET_FILTER,
	SET_STATUS,
	SET_TOTAL_PAGE,
	SET_CURRENT_PAGE,
	SET_BULK_ACTION,
	SET_ACTION,
} from "@/core/services/store/listing.module";
import { GET, QUERY } from "@/core/services/store/request.module";
import { mapGetters } from "vuex";
import { toSafeInteger } from "lodash";
import { getCurrentUser } from "@/core/services/jwt.service";
import objectPath from "object-path";

export default {
	name: "claims-listing",
	title: "Listing CLaims",
	mixins: [ListingMixin],
	data() {
		return {
			claimTypes: [
				{ text: "Customer Claim", value: "customer" },
				{ text: "Lead Claim", value: "lead" },
				{ text: "Other Claim", value: "other" },
			],
			customStatus: null,
			skipBottomHeight: 75,
			actionMenu: false,
			viewType: false,
			tagItems: ["Software", "SEO"],
			filter_tag: "Software",
			labelItems: ["Hot", "Warm", "Cold"],
			categories: [],
			filter_label: "Hot",
			userItems: [],
			category_type: null,
			customerClaim: 0,
			filter_date: [],
			owanClaim: 0,
			leadClaim: 0,
			user_filter: null,
			drawerFilter: false,
			addvanceFilterAarry: [],
			pageTitle: "Claims",
			pageBreadcrumbs: [{ text: "claims", disabled: true }],
			endpoint: "claim",
			query: null,
			defaultFilter: {},
			statusLists: [],
			status: "all",
			statusFilterData: [],
			summary_show: true,
			loginUser: null,
		};
	},
	components: {
		//ClaimsKanban,
		ClaimsKanbanNew: () => import("@/view/module/claims/ClaimsKanbanNew"),
		DateRangePicker: () => import("@/view/components/DateRangePickerV2"),
		AdvanceFilter: () => import("@/view/pages/leads/AdvanceFilter"),
		PageSummeryBox: () => import("@/view/components/PageSummeryBox"),
	},
	methods: {
		allowUserFilter() {
			let master =
				objectPath.get(this.loginUser, "is_master") && objectPath.get(this.loginUser, "is_master") == 1;

			let type = this.loginUser && this.loginUser.users_type;

			let role =
				this.loginUser &&
				(this.loginUser.role == 1 ||
					this.loginUser.role == 4 ||
					this.loginUser.role == 6 ||
					this.loginUser.role == 7 ||
					this.loginUser.role == 12 ||
					this.loginUser.role == 13)
					? true
					: false;

			return master || type == "accounts" || role ? true : false;
		},
		getClaimStatus() {
			this.$store
				.dispatch(GET, { url: "claim-setting" })
				.then((data) => {
					this.userItems = data.users;
					this.owanClaim = data.owanClaim;
					this.leadClaim = data.leadClaim;
					this.customerClaim = data.customerClaim;
					this.categories = data.expense_category.map((row) => {
						return { ...row, value: row.category, text: row.category };
					});
				})
				.catch((error) => {
					console.log({ error });
				})
				.finally(() => {
					this.pageLoading = false;
				});
		},
		dataFilter(value) {
			this.addvanceFilterAarry = value;
		},
		statusFilterRows(value) {
			this.status = value;
			this.allFiltersStatus(value);

			this.$store
				.dispatch(QUERY, {
					url: `claim`,
					data: {
						status: value,
						expense_category: this.category_type ? this.category_type : null,
						user_filter: this.user_filter ? this.user_filter : null,
						date: this.filter_date && this.filter_date.length > 1 ? this.filter_date : null,
						customStatus: this.customStatus ? this.customStatus : null,
						page: 1,
					},
				})
				.then((data) => {
					//console.log(data.status);
					this.$store.commit(SET_ACTION, data.action);
					this.$store.commit(SET_BULK_ACTION, data.bulk_action);
					this.$store.commit(SET_CURRENT_PAGE, data.current_page);
					this.$store.commit(SET_TOTAL_PAGE, data.total_page);
					this.$store.commit(SET_SHOWING_STRING, data.showing_string);
					this.$store.commit(SET_STATUS, data.status);
					this.$store.commit(SET_FILTER, data.filter);
					this.$store.commit(SET_TBODY, data.tbody);
					this.$store.commit(SET_THEAD, data.thead);
					this.statusLists = data.status;
					this.addvanceFilterAarry = [];
				})
				.catch((error) => {
					console.log({
						error,
					});
				});
		},
		commanFilter(/* para = "", para2 = "" */) {
			/* console.log("commanFilter =", { para, para2 }); */
			//let customStatus = null;
			//console.log('run');
			//if (para2 == "category" && (para == "lead" || para == "other" || para == "customer")) {
			//this.customStatus = para;
			//} else {
			//this.customStatus = null;
			//}

			//if(para2=='date'){
			//	this.filter_date = [];
			//}

			this.allFiltersSetting();

			// if (Array.isArray(this.filter_date) && this.filter_date.length > 1) {
			// 	//Array.isArray($event) && $event.length > 1
			// 	if (moment(this.filter_date[0]).isAfter(this.filter_date[1])) {
			// 		this.$store.commit(SET_ERROR, [
			// 			{ model: true, message: `Start Date can not be less than End Date` },
			// 		]);

			// 		return false;
			// 	}
			// }

			this.$store
				.dispatch(QUERY, {
					url: `claim`,
					data: {
						expense_category: this.category_type,
						user_filter: this.user_filter,
						date: this.filter_date.length > 1 ? this.filter_date : [],
						customStatus: this.customStatus,
						status: this.status,
					},
				})
				.then((data) => {
					this.$store.commit(SET_ACTION, data.action);
					this.$store.commit(SET_BULK_ACTION, data.bulk_action);
					this.$store.commit(SET_CURRENT_PAGE, data.current_page);
					this.$store.commit(SET_TOTAL_PAGE, data.total_page);
					this.$store.commit(SET_SHOWING_STRING, data.showing_string);
					this.$store.commit(SET_STATUS, data.status);
					this.$store.commit(SET_FILTER, data.filter);
					this.$store.commit(SET_TBODY, data.tbody);
					this.$store.commit(SET_THEAD, data.thead);
					this.statusLists = data.status;
				})
				.catch((error) => {
					console.log({
						error,
					});
				});
		},
		allFiltersStatus(value) {
			this.$router.push({
				name: "claims",
				query: {
					...this.$route.query,
					status: value,
					page: 1,
					t: new Date().getTime(),
				},
			});
		},
		allFiltersSetting() {
			this.$router.push({
				name: "claims",
				query: {
					expense_category: this.category_type,
					user_filter: this.user_filter,
					customStatus: this.customStatus,
					date: this.filter_date && this.filter_date.length > 1 ? this.filter_date : null,
					t: new Date().getTime(),
				},
			});
		},
		routeSetting() {
			this.$router.push({
				name: "setting",
				query: {
					tab: "claim",
				},
			});
		},
	},
	created() {
		if (!Array.isArray(this.$route.query.date)) {
			if (this.$route.query.date) this.filter_date = [this.$route.query.date, this.$route.query.date];
		} else {
			this.filter_date = this.$route.query.date;
		}
	},
	computed: {
		...mapGetters(["currentUser", "listingStatus"]),
	},
	beforeMount() {
		this.user_filter = toSafeInteger(this.$route.query.user_filter);
		this.category_type = this.$route.query.expense_category;
		this.filter_date = this.$route.query.date;
		this.customStatus = this.$route.query.customStatus;
		if ((this.currentUser.role == 1 || this.currentUser.role == 4) && !this.user_filter) {
			this.user_filter = this.$route.query.user_filter;
		}
		/* this.defaultFilter = {
			user_filter: this.user_filter ? this.user_filter : null,
			expense_category: this.category_type ? this.category_type : null,
			filter_date: this.filter_date ? this.filter_date : null,
		}; */
	},

	mounted() {
		this.getClaimStatus();
		let filterQuery = this.$route.query;
		this.loginUser = getCurrentUser() || null;
		this.$router.push({
			name: "claims",
			query: {
				...filterQuery,
				t: new Date().getTime(),
			},
		});
	},
};
</script>
